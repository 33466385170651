import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material";
import { Helmet } from "react-helmet";
import Home from "./views/Home";
import Services from "./views/Services";
import AboutUs from "./views/AboutUs";
import Contact from "./views/Contact";
import Burriana from "./views/locations/Burriana";
import Onda from "./views/locations/Onda";
import VallDeUxo from "./views/locations/VallDeUxo";
import Villareal from "./views/locations/Villareal";
import PoliticasPrivacidad from "./views/PoliticasPrivacidad";

const App = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/nuestros-servicios",
      element: <Services />,
    },
    {
      path: "/nosotros",
      element: <AboutUs />,
    },
    {
      path: "/contacto",
      element: <Contact />,
    },
    //poblaciones
    {
      path: "/burriana",
      element: <Burriana />,
    },
    {
      path: "/onda",
      element: <Onda />,
    },
    {
      path: "/vall-de-uxo",
      element: <VallDeUxo />,
    },
    {
      path: "/villareal",
      element: <Villareal />,
    },
    // politicas y cookies
    {
      path: "/politicas-de-privacidad",
      element: <PoliticasPrivacidad />,
    },
  ]);
  const theme = createTheme();
  return (
    <ThemeProvider theme={theme}>
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>ALDATI CERRAJEROS</title>
        </Helmet>
        <RouterProvider router={router}></RouterProvider>
      </div>
    </ThemeProvider>
  );
};

export default App;

import { Button, styled, Typography } from "@mui/material";
import Navbar from "../components/Navbar";
import WhatsAppButton from "../components/WhatsAppButton";
import VpnKeyOutlinedIcon from "@mui/icons-material/VpnKeyOutlined";
import ServiceCard from "../components/ServiceCard";
import "./Services.css";
import CallButton from "../components/Callbutton";

const StyledTitle = styled(Typography)(({ theme }) => ({
  listStyle: "none",
  display: "flex",
  color: "#001039",
  fontFamily: "Inter",
  fontWeight: "bold",
  fontSize: "30px",
  padding: "10px 0px",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  listStyle: "none",
  fontFamily: "Inter",
  display: "flex",
  textTransform: "initial",
  color: "white",
  fontSize: "15px",
  backgroundColor: "#A50202",
  padding: "5px 25px",
  borderRadius: "5px",
  "&:hover": {
    transition: "all",
  },
}));
const serviceArray = [
  {
    img: "/servicios/1.png",
    title: "Apertura de puertas",
    desc: [
      "Uno de los puntos más importantes dentro de las aperturas de puertas es la urgencia a la hora de atender el servicio. Poder asegurar que la persona afectada podrá volver a entrar de inmediato en su casa o abrir su local es la máxima de nuestro trabajo, por este motivo, ponemos todo nuestro esfuerzo en que todo salga correctamente. ",
      "Aparte de todo esto, es importante saber que cualquier cliente de nuestros servicios de urgencia preguntará por el precio de la apertura, máxime cuando por todos es conocido que el sector de la cerrajería es uno de los más castigados por el intrusismo laboral. Por este motivo, disponemos de nuestra lista de precios preparada en cualquier momento, lo cual nos permite informar de nuestros precios a cualquier cliente que lo desee, sin necesidad de ocultar sobrecargos posteriores.",
    ],
  },
  {
    img: "/servicios/2.png",
    title: "Cambio de cerraduras",
    desc: [
      "Garantizamos siempre un cambio de cerraduras profesional y de calidad, siempre suministrando materiales de las primeras marcas del sector. Realizamos también estudios de seguridad de la cerradura de tu vivienda o local. Puedes consultarnos en cualquier momento un presupuesto ajustado y siempre de manera gratuita.",
      "Cambia la cerradura de tu vivienda para asegurar a tu familia en todo momento. Estamos disponibles para ti las 24 horas para cualquier servicio. Realizamos desplazamientos hasta tu localización y te preparamos un presupuesto en el acto para que sea lo más preciso posible. Nuestro servicio de cambio de cerraduras está disponible en cualquier población de Castellón.",
    ],
  },
  {
    img: "/servicios/3.png",
    title: "Asesoramiento en seguridad",
    desc: [
      "Nuestro equipo de expertos te ofrece asesoramiento personalizado para mejorar la seguridad de tu hogar o negocio. Te ayudamos a elegir las mejores soluciones y sistemas de protección según tus necesidades, garantizando tranquilidad y confianza en todo momento. ¡Consulta con nosotros y toma el control de tu seguridad!",
    ],
  },
];
const email = "cerrajeria.24hrs.cas@gmail.com";

const Services = () => {
  return (
    <>
      <Navbar />
      <WhatsAppButton />
      <CallButton />
      <div className="galery-main-container">
        <div className="banner-title">
          <StyledTitle sx={{ color: "white" }}>Nuestros Servicios</StyledTitle>
          <VpnKeyOutlinedIcon
            sx={{
              fontSize: "200px",
              color: "white",
              opacity: "30%",
              transform: "rotate(35deg)",
            }}
          />
        </div>
      </div>
      <div className="services-section">
        <div className="services-cards-container">
          {serviceArray.map((item, index) => (
            <div key={index}>
              <ServiceCard img={item.img} title={item.title} desc={item.desc} />
            </div>
          ))}
        </div>
      </div>
      <div style={{ marginBottom: "40px" }}>
        <div className="contact-banner">
          <Typography
            sx={{
              color: "white",
              fontFamily: "Inter",
              fontWeight: "bold",
              fontSize: "20px",
            }}
          >
            Solicita tu presupuesto
          </Typography>
          <StyledButton href={`mailto:${email}`}>CONTACTO</StyledButton>
        </div>
      </div>

      <div className="galery-footer">
        <Typography
          sx={{
            color: "white",
            fontFamily: "Inter",
            fontWeight: "regular",
            width: "80%",
            textAlign: "center",
          }}
        >
          ©2024 CERRAJERIA 24hs. todos los derechos reservados
        </Typography>
      </div>
    </>
  );
};

export default Services;

import {
  List,
  ListItem,
  ListItemIcon,
  styled,
  Typography,
} from "@mui/material";
import Navbar from "../components/Navbar";
import WhatsAppButton from "../components/WhatsAppButton";
import "./AboutUs.css";

import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import CallButton from "../components/Callbutton";

const StyledTitle = styled(Typography)(({ theme }) => ({
  listStyle: "none",
  display: "flex",
  color: "#1E2928",
  fontFamily: "Inter",
  fontWeight: "bold",
  fontSize: "30px",
  padding: "10px 0px",
}));

const StyledLabel = styled(Typography)(({ theme }) => ({
  listStyle: "none",
  display: "flex",
  color: "white",
  fontFamily: "Inter",
  fontWeight: "bold",
  fontSize: "12px",
  padding: "10px 10px 10px 12px",
  width: "95px",
  borderRadius: "2px",
  backgroundColor: "#A50202",
  "&:hover": {
    transition: "all",
  },
}));
const StyledContent = styled(Typography)(({ theme }) => ({
  listStyle: "none",
  display: "flex",
  color: "#1E2928",
  fontFamily: "Inter",
  textAlign: "justify",
  fontSize: "15px",
  width: "100%",
}));
const AboutUs = () => {
  return (
    <>
      <Navbar />
      <WhatsAppButton />
      <CallButton />
      <div className="galery-main-container">
        <div className="banner-title">
          <StyledTitle sx={{ color: "white" }}>Sobre Nosotros</StyledTitle>
          <PeopleAltOutlinedIcon
            sx={{
              fontSize: "200px",
              color: "white",
              opacity: "30%",
              transform: "rotate(20deg)",
            }}
          />
        </div>
      </div>
      <div className="our-mision-container">
        <div className="our-mision-img">
          <img
            src={"people-taking-care-office-cleaning.jpg"}
            alt="Your description"
            style={{ width: "100%", height: "100%", borderRadius: "50px" }}
          />
        </div>
        <div className="our-mision-content">
          <StyledLabel>Nuestra misión</StyledLabel>
          <StyledTitle>Nuestro compromiso</StyledTitle>
          <List sx={{ width: "100%" }}>
            <ListItem
              sx={{
                width: "100%",
                padding: "0px",
                gap: "10px",
                display: "flex",
                alignItems: "start",
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: "5%",
                  minHeight: "100%",
                }}
              >
                <FiberManualRecordIcon
                  sx={{
                    fontSize: "6px",
                    color: "black",
                    width: "100%",
                    paddingTop: "7px",
                  }}
                />
              </ListItemIcon>
              <StyledContent>
                Nuestro compromiso es ofrecer un servicio de cerrajería rápido,
                seguro y confiable para todos nuestros clientes.
              </StyledContent>
            </ListItem>
            <br />
            <ListItem
              sx={{
                display: "flex",
                alignItems: "start",
                width: "100%",
                padding: "0px",
                gap: "10px",
              }}
            >
              <ListItemIcon sx={{ minWidth: "5%", minHeight: "100%" }}>
                <FiberManualRecordIcon
                  sx={{
                    fontSize: "6px",
                    color: "black",
                    width: "100%",
                    paddingTop: "7px",
                  }}
                />
              </ListItemIcon>
              <StyledContent>
                Nuestro equipo de cerrajeros está compuesto por profesionales
                altamente capacitados, listos para resolver cualquier emergencia
                de manera eficiente.
              </StyledContent>
            </ListItem>
            <br />
            <ListItem
              sx={{
                display: "flex",
                alignItems: "start",
                width: "100%",
                padding: "0px",
                gap: "10px",
              }}
            >
              <ListItemIcon sx={{ minWidth: "5%", minHeight: "100%" }}>
                <FiberManualRecordIcon
                  sx={{
                    fontSize: "6px",
                    color: "black",
                    width: "100%",
                    paddingTop: "7px",
                  }}
                />
              </ListItemIcon>
              <StyledContent>
                Somos especialistas en la apertura de puertas, cambios de
                cerraduras, instalación de sistemas de seguridad y más.
              </StyledContent>
            </ListItem>
            <br />
            <ListItem
              sx={{
                display: "flex",
                alignItems: "start",
                width: "100%",
                padding: "0px",
                gap: "10px",
              }}
            >
              <ListItemIcon sx={{ minWidth: "5%", minHeight: "100%" }}>
                <FiberManualRecordIcon
                  sx={{
                    fontSize: "6px",
                    color: "black",
                    width: "100%",
                    paddingTop: "7px",
                  }}
                />
              </ListItemIcon>
              <StyledContent>
                Nos esforzamos en garantizar seriedad, responsabilidad y calidad
                en cada servicio, sin importar su complejidad.
              </StyledContent>
            </ListItem>
            <br />
            <ListItem
              sx={{
                display: "flex",
                alignItems: "start",
                width: "100%",
                padding: "0px",
                gap: "10px",
              }}
            >
              <ListItemIcon sx={{ minWidth: "5%", minHeight: "100%" }}>
                <FiberManualRecordIcon
                  sx={{
                    fontSize: "6px",
                    color: "black",
                    width: "100%",
                    paddingTop: "7px",
                  }}
                />
              </ListItemIcon>
              <StyledContent>
                Ofrecemos presupuestos transparentes y adaptados a las
                necesidades de cada cliente.
              </StyledContent>
            </ListItem>
            <br />
            <ListItem
              sx={{
                display: "flex",
                alignItems: "start",
                width: "100%",
                padding: "0px",
                gap: "10px",
              }}
            >
              <ListItemIcon sx={{ minWidth: "5%", minHeight: "100%" }}>
                <FiberManualRecordIcon
                  sx={{
                    fontSize: "6px",
                    color: "black",
                    width: "100%",
                    paddingTop: "6px",
                  }}
                />
              </ListItemIcon>
              <StyledContent>
                La satisfacción de nuestros clientes es nuestra principal
                prioridad, y trabajamos para ofrecer soluciones que brinden
                tranquilidad y seguridad.
              </StyledContent>
            </ListItem>
          </List>
        </div>
      </div>
      <div className="galery-footer">
        <Typography
          sx={{
            color: "white",
            fontFamily: "Inter",
            fontWeight: "regular",
            width: "80%",
            textAlign: "center",
          }}
        >
          ©2024 CERRAJERIA 24hs. todos los derechos reservados
        </Typography>
      </div>
    </>
  );
};

export default AboutUs;

import { styled, Typography } from "@mui/material";
import CallButton from "../../components/Callbutton";
import Navbar from "../../components/Navbar";
import WhatsAppButton from "../../components/WhatsAppButton";

const StyledTitle = styled(Typography)(({ theme }) => ({
  listStyle: "none",
  display: "flex",
  color: "#1E2928",
  fontFamily: "Inter",
  fontSize: "20px",
  padding: "10px 0px",
  textAlign: "start",
  fontWeight: "bold",
}));
const StyledContent = styled(Typography)(({ theme }) => ({
  listStyle: "none",
  display: "flex",
  color: "#1E2928",
  fontFamily: "Inter",
  textAlign: "justify",
  fontSize: "15px",
  width: "100%",
}));

const Burriana = () => {
  return (
    <>
      <Navbar />
      <WhatsAppButton />
      <CallButton />
      <div className="location-main-container">
        <div className="poblation-banner">
          <StyledTitle
            sx={{
              fontSize: "35px",
              textAlign: "center",
              fontWeight: "bold",
              color: "white",
            }}
          >
            BURRIANA
          </StyledTitle>
        </div>
        <br />
        <StyledTitle>CERRAJEROS URGENTES EN BURRIANA</StyledTitle>
        <div style={{ borderBottom: "1px solid #fecaca" }}></div>
        <br />
        <StyledContent>
          En Aldati Cerrajeros tenemos nuestra central en Castellón de la Plana,
          pero adicionalmente a esto, nos esforzamos por ofrecer un servicio de
          cerrajería rápido, eficiente y sobre todo, cercano. Para esto, hemos
          ampliado nuestra plantilla desplazándonos hasta cubrir toda la
          provincia. Y entre todas estas zonas, por supuesto, hemos abierto
          nuestra sede de cerrajeros en Burriana, puesto que es una de las
          poblaciones más importantes de Castellón, tanto por su número de
          habitantes como por su buena localización.
        </StyledContent>
        <br />
        <StyledContent>
          En Burriana ofrecemos la calidad y precios de nuestros servicios en
          las demás zonas, y lo más importante es que contamos con un cerrajero
          especialmente destinado allí para así garantizar unos desplazamientos
          rápidos y unos precios mucho más bajos que los demás cerrajeros que
          deben desplazarse desde otras zonas.
        </StyledContent>
        <br />
        <StyledContent>
          Como cerrajeros de urgencia en Burriana disponemos de un técnico que
          garantiza unos servicios completos y con todas las garantías de
          calidad, los cuales detallamos en esta página.
        </StyledContent>
        <br />
        <br />
        <StyledTitle>CAMBIO DE CERRADURAS EN BURRIANA</StyledTitle>
        <div style={{ borderBottom: "1px solid #fecaca" }}></div>
        <br />
        <StyledContent>
          A lo largo de los años hemos aprendido que cada zona de Castellón
          necesita un tipo de servicios, y en Burriana sabemos que, debido a su
          estilo de vida activa y de trabajo, se sufren más problemas de
          pérdidas de llaves u olvido de éstas en casa. Para poder solventar
          dichos problemas, llevamos a cabo unas aperturas de puertas en
          Burriana profesionales y rápidas, las cuales realizamos en el momento
          y sin daños a puerta o cerradura.
        </StyledContent>
        <br />
        <StyledContent>
          Nuestras herramientas especializadas para la cerrajería nos ofrecen la
          posibilidad de obtener un resultado óptimo en cada apertura, y aparte,
          nuestra experiencia en cerraduras de todo tipo hace que podamos abrir
          sin problemas cualquier puerta que se presente. Aparte de puertas
          comunes, también abrimos persianas, cajas fuertes, garajes, puertas
          industriales… Y todas las aperturas realizadas de forma urgente si es
          necesaria.
        </StyledContent>
        <br />
        <br />
        <StyledTitle>APERTURA DE PUERTAS EN BURRIANA</StyledTitle>
        <div style={{ borderBottom: "1px solid #fecaca" }}></div>
        <br />
        <StyledContent>
          Entre todos los avisos que recibimos, el de cambio de cerraduras es
          uno de los más habituales por dos razones; la primera es el cambio de
          cerradura por pérdida o robo de las llaves, por necesidad para entrar
          en la casa, y además, conseguimos evitamos que alguien que tenga las
          llaves pueda acceder con ellas.
        </StyledContent>
        <br />
        <StyledContent>
          La segunda razón es la seguridad de la vivienda y vais a ver el
          motivo. El área de Burriana y sus aledaños son zonas de gran actividad
          laboral, puesto que son zonas con mucha industria y zonas agrícolas,
          en las que la población tiene muchísima actividad y dejan sus hogares
          desprotegidos la mayor parte del día. Este es el momento que
          aprovechan los delincuentes para acceder, y tienen más peligro que
          nunca, puesto que conocen cada vez más métodos para entrar en los
          hogares, métodos que las cerraduras comunes no consiguen aguantar.
        </StyledContent>
        <br />
        <StyledContent>
          Por esta razón, en Aldati Cerrajeros siempre damos la recomendación de
          cambiar la cerradura por una especializada en seguridad, y de este
          modo, evitar todo tipo de asalto a la vivienda, porque este tipo de
          cerraduras actuales pueden repeler el uso de las últimas técnicas de
          robo, como el tan utilizado “bumping”, que se encuentra entre los más
          efectivos.
        </StyledContent>
        <br />
        <br />
        <StyledTitle>SOBRE LA CERRAJERÍA EN BURRIANA</StyledTitle>
        <div style={{ borderBottom: "1px solid #fecaca" }}></div>
        <br />
        <StyledContent>
          Como ya hemos dicho, Burriana y sus zonas cercanas tienen mucha
          actividad industrial y agrícola, y es por este motivo que disponemos
          de un servicio urgente las 24 horas, todos los días del año, para así
          poder responder en el acto a tus necesidades de cerrajería.
        </StyledContent>
        <br />
        <StyledContent>
          En nuestro número de teléfono nos encontrarás disponibles y pondremos
          respuesta a todas tus dudas sobre nuestros servicios, precios y
          disponibilidad en tu zona.
        </StyledContent>
        <br />
        <br />
        <br />
      </div>
      <div className="galery-footer">
        <Typography
          sx={{
            color: "white",
            fontFamily: "Inter",
            fontWeight: "regular",
            width: "80%",
            textAlign: "center",
          }}
        >
          ©2024 CERRAJERIA 24hs. todos los derechos reservados
        </Typography>
      </div>
    </>
  );
};
export default Burriana;

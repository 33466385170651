import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import CardActionArea from "@mui/material/CardActionArea";
import { styled } from "@mui/material";

const StyledTitle = styled(Typography)(({ theme }) => ({
  listStyle: "none",
  display: "flex",
  color: "#1E2928",
  fontFamily: "Inter",
  fontWeight: "bold",
  fontSize: "15px",
  textTransform: "uppercase",
  padding: "10px 0px",
}));

const StyledSubtitle = styled(Typography)(({ theme }) => ({
  listStyle: "none",
  display: "flex",
  color: "#1E2928",
  fontFamily: "Inter",
  fontSize: "15px",
  textAlign: "justify",
}));
export default function ServiceCard({ img, title, desc }) {
  return (
    <Card
      sx={{
        // width: "500px",
        height: "auto",
        minHeight: "650px",
        boxShadow: "0px 4px 28px -8px rgba(0,0,0,0.75)",
        margin: "auto",
      }}
    >
      <CardActionArea>
        <CardMedia component="img" height="140" image={img} alt={title} />
        <CardContent sx={{ padding: "30px" }}>
          <StyledTitle gutterBottom variant="h5" component="div">
            {title}
          </StyledTitle>
          {desc.map((item) => (
            <>
              <StyledSubtitle gutterBottom variant="body1" component="div">
                {item}
              </StyledSubtitle>
              <br />
            </>
          ))}
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

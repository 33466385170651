import { styled, Typography } from "@mui/material";
import CallButton from "../../components/Callbutton";
import Navbar from "../../components/Navbar";
import WhatsAppButton from "../../components/WhatsAppButton";

const StyledTitle = styled(Typography)(({ theme }) => ({
  listStyle: "none",
  display: "flex",
  color: "#1E2928",
  fontFamily: "Inter",
  fontSize: "20px",
  padding: "10px 0px",
  textAlign: "start",
  textTransform: "uppercase",
  fontWeight: "bold",
}));
const StyledContent = styled(Typography)(({ theme }) => ({
  listStyle: "none",
  display: "flex",
  color: "#1E2928",
  fontFamily: "Inter",
  textAlign: "justify",
  fontSize: "15px",
  width: "100%",
}));

const Onda = () => {
  return (
    <>
      <Navbar />
      <WhatsAppButton />
      <CallButton />
      <div className="location-main-container">
        <div className="poblation-banner-onda">
          <StyledTitle
            sx={{
              fontSize: "35px",
              textAlign: "center",
              fontWeight: "bold",
              color: "white",
            }}
          >
            ONDA
          </StyledTitle>
        </div>
        <br />
        <StyledTitle>CERRAJEROS A DOMICILIO EN ONDA</StyledTitle>
        <div style={{ borderBottom: "1px solid #fecaca" }}></div>
        <br />
        <StyledContent>
          Para nosotros, ser cerrajero en Onda significa atender siempre los
          servicios de los clientes de manera urgente sin hacerlos esperar. Por
          este motivo sabemos que estar cerca de vosotros es importante para
          poder prestar el mejor servicio posible. Por ello, en Vicente Sánchez
          Cerrajeros hemos querido disponer de un equipo técnico en cada
          localidad importante de Castellón.
        </StyledContent>
        <br />
        <StyledContent>
          Para poder conseguir este objetivo, Onda es una localidad
          imprescindible en nuestros planes de expansión, una zona desde la cual
          poder cubrir toda la población desde dentro y a la vez, desplazarnos a
          las zonas cercanas de forma inmediata.
        </StyledContent>
        <br />
        <StyledTitle>SERVICIOS DE CERRAJERÍA EN ONDA</StyledTitle>
        <div style={{ borderBottom: "1px solid #fecaca" }}></div>
        <br />
        <StyledContent>
          Nuestro compañero en Onda es cerrajero profesional con más de 20 años
          de experiencia en el sector, y además de todos los servicios normales,
          también realiza servicios de especialización, como por ejemplo, la
          apertura de coches, cajas fuertes y persianas. En esta página os vamos
          a explicar nuestros trabajos en Onda y todos sus detalles.
        </StyledContent>
        <br />
        <StyledTitle sx={{ fontSize: "15px", fontWeight: "bold" }}>
          Apertura de puertas
        </StyledTitle>
        <br />
        <StyledContent>
          El servicio de apertura de puertas es, de igual forma que en las otras
          localidades, es el que más nos solicitan a los cerrajeros,
          principalmente las aperturas por errores personales, y es que no es de
          extrañar, debido al tipo de vida ajetreada que llevamos, que se nos
          olviden las llaves dentro de casa al salir y nos quedemos sin poder
          entrar de nuevo. La solución a este problema es estar en todo momento
          disponibles para acudir en el acto.
        </StyledContent>
        <br />
        <StyledContent>
          De forma que podamos abrir cada puerta de forma eficiente, empleamos
          las mejores herramientas del sector de la cerrajería, las cuales nos
          permiten abrirlas sin causar daños a los marcos o cerraduras.
        </StyledContent>
        <br />
        <StyledContent>
          Adicionalmente, y mirando siempre por la seguridad de la vivienda,
          cambiamos las cerraduras antiguas por unas de seguridad actuales, de
          forma que será muchísimo más complicado para los ladrones el acceso a
          la casa, puesto que dichas cerraduras nos protegen contra casi todos
          los métodos de asalto que se conocen actualmente.
        </StyledContent>
        <br />
        <StyledTitle sx={{ fontSize: "15px", fontWeight: "bold" }}>
          Apertura de vehículos
        </StyledTitle>
        <br />
        <StyledContent>
          Hay que tener en cuenta que cualquier cerrajero no es capaz de abrir
          un vehículo sin su llave. Para poder hacerlo correctamente, un
          cerrajero tiene que realizar diferentes cursos de preparación puesto
          que cada cerradura de vehículo es diferente, pero si se conoce la
          técnica, se pueden abrir sin problemas.
        </StyledContent>
        <br />
        <StyledContent>
          Lo primero que necesitamos saber para poder realizar la apertura de tu
          coche es saber marca y modelo de éste, y si es posible, el año en el
          que se fabricó, ya que las cerraduras y sistemas de cierre van
          cambiando con el tiempo. Una vez tengamos toda la información, nos
          pondremos en camino hasta donde esté aparcado el vehículo sin hacerte
          esperar. Todas las aperturas se hacen en el acto, y casi siempre, se
          realizan sin causar ningún daño.
        </StyledContent>
        <br />
        <StyledTitle sx={{ fontSize: "15px", fontWeight: "bold" }}>
          Apertura de cajas fuertes
        </StyledTitle>
        <br />
        <StyledContent>
          Uno de los dispositivos más complicados de manipular en el sector de
          la cerrajería son las cajas fuertes. Éstas están fabricadas en
          exclusiva para evitar que nadie pueda acceder a los elementos que
          guardemos dentro. Hay que tener en cuenta que su fin es impedir que se
          puedan abrir sin su llave, por lo que abrirla sin dañarla y que siga
          funcionando, es algo muy complicado de lograr, ya que se bloquean
          completamente sin su llave o contraseña.
        </StyledContent>
        <br />
        <StyledContent>
          Depende del modelo y tipo de caja fuerte, de su material y su sistema
          de cierre, éstas se abren de una u otra manera, y debido a esto es
          importante que nos des toda la información posible a la hora de
          realizar el servicio
        </StyledContent>
        <br />
        <StyledTitle>SOBRE LA CERRAJERÍA EN ONDA</StyledTitle>
        <br />
        <StyledContent>
          En Vicente Sánchez, como cerrajeros en Onda, nos especializamos en
          ofrecer un servicio de cerrajería completo, el cual incluye servicios
          como apertura de persianas, sistemas de seguridad, automatismos o
          cerraduras nuevas.
        </StyledContent>
        <br />
        <StyledContent>
          La integridad de nuestros servicios de cerrajería se pueden solicitar
          con urgencia, los cuales tienen una disponibilidad de 24h en toda la
          localidad de Onda y alrededores. En nuestro número de teléfono te
          atenderemos y ayudaremos en todo lo que necesites.
        </StyledContent>
        <br />
        <br />
        <br />
      </div>
      <div className="galery-footer">
        <Typography
          sx={{
            color: "white",
            fontFamily: "Inter",
            fontWeight: "regular",
            width: "80%",
            textAlign: "center",
          }}
        >
          ©2024 CERRAJERIA 24hs. todos los derechos reservados
        </Typography>
      </div>
    </>
  );
};
export default Onda;

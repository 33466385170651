import { styled, Typography } from "@mui/material";
import NavBar from "../components/Navbar";
const StyledTitle = styled(Typography)(({ theme }) => ({
  listStyle: "none",
  display: "flex",
  color: "#1E2928",
  fontFamily: "Inter",
  fontSize: "25px",
  padding: "10px 0px",
  textAlign: "start",
  fontWeight: "bold",
}));
const StyledContent = styled(Typography)(({ theme }) => ({
  listStyle: "none",
  display: "flex",
  color: "#1E2928",
  fontFamily: "Inter",
  textAlign: "justify",
  fontSize: "15px",
  width: "100%",
}));
const PoliticasPrivacidad = () => {
  return (
    <>
      <NavBar />
      <div className="location-main-container">
        <StyledTitle>Política de Protección de Datos</StyledTitle>
        <br />
        <StyledContent>
          En cumplimiento del REGLAMENTO (UE) 2016/679 DEL PARLAMENTO EUROPEO Y
          DEL CONSEJO de 27 de abril de 2016, relativo a la protección de las
          personas físicas en lo que respecta al tratamiento de datos personales
          y a la libre circulación de estos datos y su normativa de desarrollo,
          se le informa que los datos personales facilitados por usted serán
          tratados con la finalidad de facilitarle la información solicitada
          acerca de nuestros productos y servicios.
        </StyledContent>
        <br />
        <StyledContent>
          A través de la cumplimentación de formularios de la Web o mediante el
          envío de correos electrónicos o cualquier otro tipo de solicitud de
          información remitida a LA EMPRESA, el interesado presta su
          consentimiento expreso para el tratamiento de sus datos personales,
          para recibir comunicaciones comerciales sobre los productos y
          servicios de LA EMPRESA.
        </StyledContent>
        <br />
        <StyledContent>
          En ningún caso LA EMPRESA, utilizará los datos personales de los
          interesados para fines distintos de los anteriormente mencionados, ni
          los comunicará a terceros sin el consentimiento previo y expreso del
          afectado, y se compromete a guardar el debido secreto profesional y a
          establecer las medidas técnicas y organizativas necesarias para
          salvaguardar la información conforme a los requerimientos que
          establece el mencionado Reglamento.
        </StyledContent>
        <br />
        <StyledContent>
          Para mantenerle informado sobre novedades, promociones o nuevos
          productos, sus datos personales se conservarán en nuestros registros
          durante un periodo de tres años. No obstante, en cualquier momento
          usted puede solicitar el acceso a sus datos personales, y su
          rectificación o supresión. Así como, limitar su tratamiento, o
          directamente oponerse al tratamiento, o ejercer el derecho a la
          portabilidad de los mismos.
        </StyledContent>
        <br />
        <StyledContent>
          También le informamos de su derecho a presentar una reclamación ante
          la Agencia Española de Protección de Datos, si considera que en el
          tratamiento de sus datos no se están respetando sus derechos.
        </StyledContent>
        <br />
        <br />
        <StyledTitle>Política de protección de menores</StyledTitle>
        <br />
        <StyledContent>
          Quien facilita los datos a través de los formularios de esta Web y
          acepta su tratamiento declara formalmente ser mayor de 14 años. Queda
          prohibido el acceso y uso del portal a los menores de 14 años de edad.
        </StyledContent>
        <br />
        <StyledContent>
          LA EMPRESA recuerda a las personas mayores de edad que tengan a su
          cargo menores, que será de su exclusiva responsabilidad si algún menor
          incorpora sus datos para solicitar algún servicio.
        </StyledContent>
        <br />
        <StyledContent>
          También les informa que existen programas informáticos para acotar la
          navegación mediante el filtro o bloqueo a determinados contenidos
        </StyledContent>
        <br />
        <br />
        <StyledTitle>Redes sociales</StyledTitle>
        <br />
        <StyledContent>
          Al hacerse amigo de nuestra red social en Facebook, Instagram o
          twitter, consiente expresamente en el tratamiento de sus datos
          personales en el entorno de esas redes sociales conforme a su política
          de privacidad. También consiente expresamente a que las noticias
          publicadas sobre cualquier producto o servicio de LA EMPRESA aparezcan
          en su muro.
        </StyledContent>
        <br />
        <StyledContent>
          Le informamos que los datos personales de los amigos, fans o miembros
          del grupo LA EMPRESA en las redes sociales mencionadas anteriormente,
          serán incorporados a un fichero cuyo titular es LA EMPRESA  con la
          finalidad de hacerle llegar información sobre nuestros productos y
          servicios, sorteos y concursos, o enviarle comunicaciones
          publicitarias o promocionales por correo electrónico u otro medio de
          comunicación electrónica equivalente, así como para mantenerle
          informados a través de las redes sociales sobre noticias relativas a
          las actividades de LA EMPRESA
        </StyledContent>
        <br />
        <StyledContent>
          Su petición de amistad o integración como fan o miembro del grupo,
          implica necesariamente su consentimiento para los tratamientos
          señalados.
        </StyledContent>
        <br />
        <StyledContent>
          La publicación de comentarios y contenidos en las redes sociales se
          convertirá en información pública, por lo que los usuarios deberán
          tener especial cautela cuando decidan compartir su información
          personal. LA EMPRESA no se hace responsable por la información que los
          usuarios incorporen en la página. No obstante, las personas cuyos
          datos personales se encuentren publicados o estén incluidos en
          comentarios, podrán solicitar a  LA EMPRESA la cancelación de los
          mismos.
        </StyledContent>
        <br />
        <StyledContent>
          En cualquier momento, usted puede ejercer los derechos de acceso,
          rectificación, cancelación y oposición mediante escrito, acompañado de
          copia de documento oficial que le identifique, dirigido LA EMPRESA en
          el domicilio indicado anteriormente.
        </StyledContent>
        <br />
        <br />
        <br />
      </div>
      <div className="galery-footer">
        <Typography
          sx={{
            color: "white",
            fontFamily: "Inter",
            fontWeight: "regular",
            width: "80%",
            textAlign: "center",
          }}
        >
          ©2024 CERRAJERIA 24hs. todos los derechos reservados
        </Typography>
      </div>
    </>
  );
};
export default PoliticasPrivacidad;

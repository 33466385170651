import { styled, Typography } from "@mui/material";
import Navbar from "../components/Navbar";
import WhatsAppButton from "../components/WhatsAppButton";
import CallIcon from "@mui/icons-material/Call";
import AlternateEmailOutlinedIcon from "@mui/icons-material/AlternateEmailOutlined";
import RateReviewOutlinedIcon from "@mui/icons-material/RateReviewOutlined";
import "./Contact.css";
import ContactForm from "../components/ContactForm";
import CallButton from "../components/Callbutton";

const StyledTitle = styled(Typography)(({ theme }) => ({
  listStyle: "none",
  display: "flex",
  color: "#001039",
  fontFamily: "Inter",
  fontWeight: "bold",
  fontSize: "30px",
  padding: "10px 0px",
}));

const Contact = () => {
  return (
    <>
      <Navbar />
      <WhatsAppButton />
      <CallButton />
      <div className="galery-main-container">
        <div className="banner-title">
          <StyledTitle sx={{ color: "white" }}>Contacto</StyledTitle>
          <RateReviewOutlinedIcon
            sx={{
              fontSize: "200px",
              color: "white",
              opacity: "30%",
              transform: "rotate(20deg)",
            }}
          />
        </div>
      </div>
      <div className="contact-content">
        <div className="img-contact"></div>
        <div className="form-contact">
          <ContactForm />
        </div>
      </div>
      <div className="contact-data-content">
        <StyledTitle
          sx={{
            fontSize: "20px",
            color: "white",
            textTransform: "uppercase",
            margin: "auto",
            textAlign: "center",
          }}
        >
          ¿Quieres comunicarte de manera directa?
        </StyledTitle>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "auto",
            width: "100%",
            marginTop: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              gap: "5px",
              justifyContent: "center",
            }}
          >
            <AlternateEmailOutlinedIcon sx={{ color: "#7f1d1d" }} />
            <StyledTitle
              sx={{
                fontSize: "15px",
                color: "white",
                textTransform: "uppercase",
                textAlign: "center",
                fontFamily: "inter",
              }}
            >
              cerrajeria.24hrs.cas@gmail.com
            </StyledTitle>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              gap: "5px",
              justifyContent: "center",
            }}
          >
            <CallIcon sx={{ color: "#7f1d1d" }} />
            <StyledTitle
              sx={{
                fontSize: "15px",
                color: "white",
                textTransform: "uppercase",
                textAlign: "center",
              }}
            >
              + 34 665 907 125
            </StyledTitle>
          </div>
        </div>
      </div>
      <div className="galery-footer">
        <Typography
          sx={{
            color: "white",
            fontFamily: "Inter",
            fontWeight: "regular",
            width: "80%",
            textAlign: "center",
            textTransform: "uppercase",
          }}
        >
          ©2024 CERRAJERIA 24hs. todos los derechos reservados
        </Typography>
      </div>
    </>
  );
};

export default Contact;

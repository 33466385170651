import React from "react";
import { Button, styled, Typography } from "@mui/material";
import "./Opinions.css";
import OpinionSlider from "./OpinionSlider";

const StyledTitle = styled(Typography)(({ theme }) => ({
  listStyle: "none",
  display: "flex",
  color: "#1E2928",
  fontFamily: "Inter",
  fontWeight: "bold",
  fontSize: "25px",
  textAlign: "center",
  padding: "10px 0px",
}));
const StyledOpinionButton = styled(Button)(({ theme }) => ({
  margin: 4,
  listStyle: "none",
  display: "flex",
  fontFamily: "Inter",
  backgroundColor: "#A50202",
  color: "white",
  fontSize: "15px",
  fontWeight: "bold",
  width: "150px",
  padding: "5px 25px",
  "&:hover": {
    color: "white",
    backgroundColor: "#171717",
    transition: "all",
  },
}));

const Opinions = () => {
  const email = "cerrajeria.24hrs.cas@gmail.com";
  return (
    <div class="outer-div">
      <div className="opinions-outer-title">
        <StyledTitle>Lo que opinan nuestros clientes</StyledTitle>
      </div>
      <div class="inner-div">
        <div className="opinions-inner-title">
          <StyledTitle sx={{ color: "white" }}>
            Queremos saber tu opinión, ¡deja tu reseña aquí!
          </StyledTitle>
          <StyledOpinionButton href={`mailto:${email}`}>
            DAR RESEÑA
          </StyledOpinionButton>
        </div>
      </div>
      <div class="background-div" id="contacto">
        <div className="content-container">
          <div
            className="title-banner"
            item
            xs={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              padding: 2,
            }}
          >
            <OpinionSlider />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Opinions;

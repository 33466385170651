import React, { useState } from "react";
import {
  Button,
  Container,
  CircularProgress,
  Typography,
  styled,
  Checkbox,
  FormControlLabel,
  Link,
  FormControl,
  FormHelperText,
} from "@mui/material";

const StyledSubmitButton = styled(Button)(({ theme }) => ({
  listStyle: "none",
  fontFamily: "Inter",
  display: "flex",
  textTransform: "initial",
  color: "white",
  width: "40%",
  backgroundColor: "#171717",
  fontSize: "15px",
  justifyContent: "center",
  alignItems: "center",
  padding: "5px",
  "&:hover": {
    color: "white",
    transition: "all",
  },
}));

const prodUrl = process.env.REACT_APP_API_URL;
const apiKey = process.env.REACT_APP_API_KEY;

const ContactForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isMailComplete, setIsMailComplete] = useState(false);
  const [isMailRejected, setIsMailRejected] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setIsMailComplete(false);
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    setIsAccepted(e.target.checked);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isAccepted) {
      alert("Debes aceptar las políticas de privacidad.");
      return;
    }

    try {
      setIsMailComplete(false);
      setIsLoading(true);
      const response = await fetch(prodUrl + "/send-email.php", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-KEY": apiKey,
        },
        body: JSON.stringify(formData),
      });
      const result = await response.json();
      if (result) {
        setIsLoading(false);
        if (result.status === 200) {
          setFormData({
            name: "",
            email: "",
            phone: "",
            message: "",
          });
          setIsMailComplete(true);
        } else {
          setIsMailRejected(true);
        }
      }
    } catch (error) {
      setIsLoading(false);
      setIsMailRejected(true);
      console.log(error);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ padding: "0px" }}>
      <Typography
        sx={{
          fontFamily: "Inter",
          fontWeight: "bold",
          fontSize: "20px",
          color: "#1E2928",
          textTransform: "uppercase",
          marginBottom: "10px",
        }}
      >
        Comunicate con nosotros
      </Typography>
      <form onSubmit={handleSubmit}>
        <div style={{ margin: "normal" }}>
          <label>
            Nombre:
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              style={{ width: "90%", margin: "8px 0", padding: "10px" }}
            />
          </label>
        </div>
        <div style={{ margin: "normal" }}>
          <label>
            Email:
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              style={{ width: "90%", margin: "8px 0", padding: "10px" }}
            />
          </label>
        </div>
        <div style={{ margin: "normal" }}>
          <label>
            Teléfono:
            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              style={{ width: "90%", margin: "8px 0", padding: "10px" }}
            />
          </label>
        </div>
        <div style={{ margin: "normal" }}>
          <label>
            Mensaje:
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
              rows={4}
              style={{ width: "90%", margin: "8px 0", padding: "10px" }}
            />
          </label>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "5px",
          }}
        >
          {isLoading && <CircularProgress />}
          {isMailComplete && (
            <Typography style={{ color: "#65a30d", fontFamily: "Inter" }}>
              Mensaje enviado correctamente.
            </Typography>
          )}
          {isMailRejected && (
            <Typography style={{ color: "#b91c1c", fontFamily: "Inter" }}>
              No se pudo enviar tu mensaje.
            </Typography>
          )}
        </div>
        <div
          style={{ margin: "normal", display: "flex", alignItems: "center" }}
        >
          <FormControl required error={!isAccepted}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isAccepted}
                  onChange={handleCheckboxChange}
                  color="primary"
                />
              }
              label={
                <>
                  Acepto las{" "}
                  <Link href="/politicas-de-privacidad">
                    Políticas de privacidad
                  </Link>{" "}
                </>
              }
            />
            {!isAccepted && (
              <FormHelperText>Debes aceptar las políticas.</FormHelperText>
            )}
          </FormControl>
        </div>

        <StyledSubmitButton type="submit">Enviar</StyledSubmitButton>
      </form>
    </Container>
  );
};

export default ContactForm;

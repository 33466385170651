import React from "react";
import { Fab } from "@mui/material";
import CallIcon from "@mui/icons-material/Call";

const CallButton = () => {
  const phoneNumber = "+34665907125"; // Replace with your phone number
  const url = `tel:${phoneNumber}`;

  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      style={{ position: "fixed", bottom: 85, right: 20, zIndex: 1000 }}
    >
      <Fab sx={{ backgroundColor: "#1d4ed8" }} aria-label="Call">
        <CallIcon />
      </Fab>
    </a>
  );
};

export default CallButton;

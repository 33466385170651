import { styled, Typography } from "@mui/material";
import CallButton from "../../components/Callbutton";
import Navbar from "../../components/Navbar";
import WhatsAppButton from "../../components/WhatsAppButton";

const StyledTitle = styled(Typography)(({ theme }) => ({
  listStyle: "none",
  display: "flex",
  color: "#1E2928",
  fontFamily: "Inter",
  fontSize: "20px",
  padding: "10px 0px",
  textAlign: "start",
  textTransform: "uppercase",
  fontWeight: "bold",
}));
const StyledContent = styled(Typography)(({ theme }) => ({
  listStyle: "none",
  display: "flex",
  color: "#1E2928",
  fontFamily: "Inter",
  textAlign: "justify",
  fontSize: "15px",
  width: "100%",
}));

const VallDeUxo = () => {
  return (
    <>
      <Navbar />
      <WhatsAppButton />
      <CallButton />
      <div className="location-main-container">
        <div className="poblation-banner-uxo">
          <StyledTitle
            sx={{
              fontSize: "35px",
              textAlign: "center",
              fontWeight: "bold",
              color: "white",
              textTransform: "uppercase",
            }}
          >
            Vall de Uxó
          </StyledTitle>
        </div>
        <br />
        <StyledTitle>CERRAJERÍA A DOMICILIO EN VALL DE UXÓ</StyledTitle>
        <div style={{ borderBottom: "1px solid #fecaca" }}></div>
        <br />
        <StyledContent>
          Castellón posee una cualidad muy interesante; la diferencia que existe
          entre sus poblaciones, entre las que podemos encontrar áreas
          turísticas, de montaña, pueblos tranquilos y zonas de industria. Pero
          no sólo en esto reside su diversidad, también en el modo de vida de
          cada zona, y por tanto, los comercios se adaptan a esto. El gremio de
          la cerrajería también tiene que hacerlo, y nosotros, como cerrajeros
          24 horas en Vall de Uxó, también debemos ajustarnos a las necesidades
          de cada localidad y así poder garantizar el servicio óptimo para cada
          una.
        </StyledContent>
        <br />
        <StyledContent>
          Como en las demás poblaciones de Castellón, en Vall de Uxó disponemos
          de un cerrajero destinado allí mismo, capaz de acudir a cualquier
          servicio de la localidad en cuestión de minutos y seguir haciendo un
          trabajo de la mejor calidad.
        </StyledContent>
        <br />
        <StyledTitle>SERVICIOS EN VALL DE UXÓ</StyledTitle>
        <div style={{ borderBottom: "1px solid #fecaca" }}></div>
        <br />
        <StyledContent>
          La Vall de Uxó es una población de interior, y éstas requieren unos
          servicios diferentes a las poblaciones de costa. En esta ocasión, los
          servicios que más prestamos en la zona son éstos.
        </StyledContent>
        <br />
        <StyledTitle sx={{ fontSize: "15px", fontWeight: "bold" }}>
          Apertura de puertas
        </StyledTitle>
        <br />
        <StyledContent>
          La gran mayoría de aperturas que nos llegan desde la Vall de Uxó son
          producidas por errores de los propietarios de las viviendas, como
          llaves dentro de casa o pérdida de las mismas. Tenemos en cuenta que
          más del 70% de las llamadas que recibimos tienen el problema de tener
          que abrir la puerta por un descuido. Aunque no lo parezca, esto no es
          malo del todo, puesto que basta con una simple apertura para seguir
          usando todo como de costumbre, ya que si fuese por un robo, sería
          necesario cambiar también la cerradura para evitar males mayores.
        </StyledContent>
        <br />
        <StyledContent>
          Nuestras aperturas de puertas en la Vall de Uxó tienen toda la calidad
          que garantizamos en Vicente Sánchez Cerrajeros, cuentan con la
          posibilidad de realizarlas con urgencia, lo cual significa que
          acudimos en el mismo instante en el que nos llamas, para así no tener
          que hacerte esperar fuera de casa. Cuando llega el momento de efectuar
          la apertura, tenemos listo un presupuesto previo, de forma que no se
          incremente el precio una vez realizado el trabajo y te ahorres sustos
          y problemas innecesarios.
        </StyledContent>
        <br />
        <StyledContent>
          Si se diese el caso, como comentábamos antes, de que la apertura se ha
          realizado por un robo o un asalto, es posible también realizar un
          cambio de cerradura en el mismo momento, y así evitar futuros
          problemas. Disponemos de los mejores sistemas de cerraduras del
          gremio, por lo que nadie podrá acceder más.
        </StyledContent>
        <br />
        <StyledTitle sx={{ fontSize: "15px", fontWeight: "bold" }}>
          Automatizaciones en el hogar
        </StyledTitle>
        <br />
        <StyledContent>
          Aunque pueda parecer extraño, uno de los trabajos más solicitados en
          la Vall de Uxó consiste en la instalación de automatismos del hogar.
        </StyledContent>
        <br />
        <StyledContent>
          Entre todas los automatismos que nos solicitan, los más demandados son
          los motores de persiana en casa, algo que es muy interesante en el
          hogar, ya que nos facilitará mucho el subir y bajar cada persiana sin
          esfuerzo. Incluso, con los motores más actuales, nos permiten
          manejarlos a distancia con un mando especial o incluso desde una
          aplicación móvil, con la cual se pueden incluso programar a la hora
          que queramos que suban o bajen sin necesidad de estar en casa para
          hacerlo.
        </StyledContent>
        <br />
        <StyledContent>
          Las persianas no son el único elemento que se puede automatizar,
          también cuentan algunas puertas y ventanas de casa, verjas y cancelas
          o puertas de garaje de todo tipo.
        </StyledContent>
        <br />
        <StyledTitle>SOBRE NUESTROS CERRAJEROS EN VALL DE UXÓ</StyledTitle>
        <div style={{ borderBottom: "1px solid #fecaca" }}></div>
        <br />
        <StyledContent>
          La Vall de Uxó entra en nuestra lista de localidades más importantes
          de Castellón, en la cual tenemos a nuestro cerrajero destinado en la
          zona, de forma que nadie tenga que esperar para recibir su servicio.
        </StyledContent>
        <br />
        <StyledContent>
          Todos los trabajos que realizamos en la Vall de Uxó cuentan con la
          garantía de Vicente Sánchez Cerrajeros, y están realizados por un
          cerrajero experto en el sector, con más de 20 años de experiencia.
          Estamos disponibles las 24 horas para prestar un servicio de calidad y
          con los mejores precios de la zona, ahorrándote un desplazamiento
          excesivo y con un tiempo de espera mínimo.
        </StyledContent>
        <br />
        <StyledContent>
          Como siempre, para contactar con nosotros, lo único que tienes que
          hacer es llamarnos o escribirnos un correo y te responderemos en el
          acto a todo lo que necesites saber, tanto presupuesto como guía para
          solucionar tus problemas.
        </StyledContent>
        <br />
        <br />
        <br />
      </div>
      <div className="galery-footer">
        <Typography
          sx={{
            color: "white",
            fontFamily: "Inter",
            fontWeight: "regular",
            width: "80%",
            textAlign: "center",
          }}
        >
          ©2024 CERRAJERIA 24hs. todos los derechos reservados
        </Typography>
      </div>
    </>
  );
};
export default VallDeUxo;

import React, { useState } from "react";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { Button, Collapse, styled } from "@mui/material";
import { Link } from "react-router-dom";

const StyledButton = styled(Button)(({ theme }) => ({
  listStyle: "none",
  display: "flex",
  fontFamily: "inter",
  fontWeight: "bold",
  color: "black",
  fontSize: "15px",
  textAlign: "left",
  justifyContent: "flex-start",
  textTransform: "capitalize",
  transition: "all",
  "&:hover": {
    color: "#A50202",
    backgroundColor: "white",
  },
}));
const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: "white",
  backgroundColor: "#171717",
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  backgroundColor: "#171717",
  boxShadow: "none",
}));

const urlRoutes = [
  {
    path: "/",
    name: "Inicio",
  },
  {
    path: "/nuestros-servicios",
    name: "Nuestros Servicios",
  },
  {
    path: "/nosotros",
    name: "Sobre Nosotros",
  },
  {
    path: "",
    name: "poblaciones",
    nestedRoutes: [
      { path: "/burriana", name: "Burriana" },
      { path: "/onda", name: "Onda" },
      { path: "/vall-de-uxo", name: "Vall de Uxó" },
      { path: "/villareal", name: "Villarreal" },
    ],
  },
  {
    path: "/contacto",
    name: "CONTACTO",
  },
];

const BurgerMenu = () => {
  const [open, setOpen] = useState(false);
  const [openCollapse, setOpenCollapse] = useState(false);

  const handleClick = (e) => {
    e.stopPropagation();
    setOpenCollapse(!openCollapse);
  };
  const toggleDrawer = (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(!open);
  };

  const list = () => (
    <div role="presentation" onClick={toggleDrawer} onKeyDown={toggleDrawer}>
      <List>
        {urlRoutes.map((item, i) => (
          <>
            <ListItem button key={i}>
              <StyledButton
                className="navbar-links"
                component={Link}
                to={item.path}
                onClick={handleClick}
              >
                {item.name}
              </StyledButton>
            </ListItem>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {item && item.nestedRoutes
                ? item.nestedRoutes.map((nestedRoute, index) => {
                    return (
                      <Collapse in={openCollapse} unmountOnExit>
                        <List
                          component="div"
                          disablePadding
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <StyledButton
                            sx={{
                              color: "red",
                              width: "80%",
                            }}
                            component={Link}
                            to={nestedRoute.path}
                          >
                            {nestedRoute.name}
                          </StyledButton>
                        </List>
                      </Collapse>
                    );
                  })
                : null}
            </div>
          </>
        ))}
      </List>
    </div>
  );

  return (
    <div>
      <StyledToolbar variant="dense">
        <StyledIconButton aria-label="menu" onClick={toggleDrawer}>
          <MenuIcon />
        </StyledIconButton>
      </StyledToolbar>

      <Drawer anchor="left" open={open} onClose={toggleDrawer}>
        {list()}
      </Drawer>
    </div>
  );
};

export default BurgerMenu;

import React, { useEffect } from "react";
import WhatsAppButton from "../components/WhatsAppButton";
import Navbar from "../components/Navbar";
import InfoCard from "../components/InfoCard";
import KeyIcon from "@mui/icons-material/Key";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import Opinions from "../components/Opinions";
import { Typography } from "@mui/material";
import AboutUsSection from "../components/AboutUsSection";
import HandshakeOutlinedIcon from "@mui/icons-material/HandshakeOutlined";
import "./Home.css";
import CallButton from "../components/Callbutton";

const cardArray = [
  {
    icon: (
      <CreditCardIcon
        style={{ fontSize: 50, color: "#A50202", paddingBottom: "20px" }}
      />
    ),
    title: "Los precios más competitivos",
    text: "Te garantizamos un servicio de alta calidad sin sorpresas en el precio.",
    showButton: true,
  },
  {
    icon: (
      <KeyIcon
        style={{ fontSize: 50, color: "#A50202", paddingBottom: "20px" }}
      />
    ),
    title: "Cerrajería urgente las 24h del día",
    text: "Entendemos que los imprevistos pueden ocurrir en cualquier momento. Por eso, ofrecemos servicios de cerrajería urgente las 24 horas del día",
    showButton: false,
  },
  {
    icon: (
      <HandshakeOutlinedIcon
        style={{ fontSize: 50, color: "#A50202", paddingBottom: "20px" }}
      />
    ),
    title: "Profesionales de Suma Confianza",
    text: "Contamos con cerrajeros altamente capacitados y confiables, listos para resolver cualquier urgencia. Tu seguridad es nuestra prioridad, ofreciendo soluciones rápidas, efectivas y con total garantía.",
    showButton: false,
  },
  {
    icon: (
      <AssignmentIndIcon
        style={{ fontSize: 50, color: "#A50202", paddingBottom: "20px" }}
      />
    ),
    title: "Atención personalizada al cliente",
    text: "Cada persona es un mundo y cada problema un reto a resolver. Es por ello que nuestros servicios son siempre personalizados, dando el 100% de nuestro conocimiento.",
    showButton: false,
  },
];
const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar />
      <WhatsAppButton />
      <CallButton />
      <div className="banner-container">
        <div className="main-banner-content">
          <Typography
            sx={{
              color: "white",
              fontFamily: "Inter",
              fontWeight: "bold",
              fontSize: "40px",
              width: "50%",
              marginBottom: "20px",
            }}
          >
            ¡Tu Seguridad, Nuestra Prioridad!
          </Typography>
          <Typography
            sx={{
              color: "white",
              // color: "#171717",
              fontFamily: "Inter",
              fontSize: "15px",
              width: "45%",
              textAlign: "justify",
            }}
          >
            Nuestro equipo está disponible las 24 horas para brindarte el
            servicio que necesitas con confianza y responsabilidad. ¡Tu
            tranquilidad es lo primero!
          </Typography>
        </div>
      </div>
      <div className="cards-container">
        <div className="card-row">
          {cardArray.map((item, index) => (
            <InfoCard
              key={index}
              icon={item.icon}
              title={item.title}
              text={item.text}
              showButton={item.showButton}
            />
          ))}
        </div>
      </div>
      <div className="about-us-container">
        <AboutUsSection />
      </div>
      <Opinions />
      <div className="footer">
        <Typography
          sx={{
            color: "black",
            fontFamily: "Inter",
            fontWeight: "regular",
            width: "80%",
            textAlign: "center",
          }}
        >
          ©2024 CERRAJERIA 24hs. todos los derechos reservados
        </Typography>
      </div>
    </>
  );
};
export default Home;

import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
const opinions = [
  {
    icon: (
      <AccountCircleIcon
        sx={{ color: "white", fontSize: 60, marginBottom: "10px" }}
      />
    ),
    name: "Alejandro Delgado",
    review:
      "Necesitaba cambiar las cerraduras de mi casa y estaba buscando opciones económicas. Esta empresa me ofreció el mejor precio y un servicio impecable. Fueron muy amables, eficientes y me explicaron todo lo que estaban haciendo. Sin duda los volveré a llamar en caso de emergencia.",
  },
  {
    icon: (
      <AccountCircleIcon
        sx={{ color: "white", fontSize: 60, marginBottom: "10px" }}
      />
    ),
    name: "Mariano Vargas",
    review:
      "Esta empresa me brindó atención inmediata y me ayudaron con una solución perfecta. Además, me ofrecieron un presupuesto claro sin sorpresas. Sin duda, son la mejor opción para cerrajería 24h",
  },
  {
    icon: (
      <AccountCircleIcon
        sx={{ color: "white", fontSize: 60, marginBottom: "10px" }}
      />
    ),
    name: "Noelia Schmidt",
    review:
      "Llegaron en menos de 20 minutos y resolvieron el problema rápidamente. El cerrajero fue muy profesional y el precio fue justo. ¡Totalmente recomendados!",
  },
];

const OpinionSlider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % opinions.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + opinions.length) % opinions.length);
  };

  useEffect(() => {
    // const interval = setInterval(nextSlide, 5000);
    // return () => clearInterval(interval);
  }, []);

  return (
    <Box
      sx={{
        position: "relative",
        width: "90%",
        margin: "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        overflow: "hidden",
        gap: "50px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          transition: "transform 0.5s ease-in-out",
          transform: `translateX(-${currentSlide * 100}%)`,
        }}
      >
        {opinions.map((opinion, index) => (
          <Box
            key={index}
            sx={{
              minWidth: "100%",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "10px",
            }}
          >
            {opinion.icon}
            <Typography
              variant="h6"
              sx={{
                color: "white",
                fontFamily: "Inter",
                fontWeight: "bold",
                width: "80%",
                textAlign: "center",
                marginTop: "15px",
              }}
            >
              {opinion.name}
            </Typography>
            <Typography
              sx={{
                color: "white",
                fontFamily: "Inter",
                fontWeight: "regular",
                width: "60%",
                textAlign: "center",
                fontSize: "13px",
              }}
            >
              {opinion.review}
            </Typography>
          </Box>
        ))}
      </Box>
      <Button
        onClick={prevSlide}
        sx={{
          position: "absolute",
          left: "10px",
          top: "50%",
          transform: "translateY(-50%)",
        }}
      >
        <ArrowCircleLeftOutlinedIcon
          sx={{
            fontSize: "40px",
            color: "white",
          }}
        />
      </Button>
      <Button
        onClick={nextSlide}
        sx={{
          position: "absolute",
          right: "10px",
          top: "50%",
          transform: "translateY(-50%)",
        }}
      >
        <ArrowCircleRightOutlinedIcon
          sx={{
            fontSize: "40px",
            color: "white",
          }}
        />
      </Button>
    </Box>
  );
};

export default OpinionSlider;

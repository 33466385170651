import React from "react";
import { Typography, styled } from "@mui/material";
import "./AboutUsSection.css";

const StyledLabel = styled(Typography)(({ theme }) => ({
  listStyle: "none",
  display: "flex",
  color: "white",
  fontFamily: "Inter",
  fontWeight: "bold",
  fontSize: "12px",
  padding: "10px 10px 10px 12px",
  width: "95px",
  borderRadius: "2px",
  backgroundColor: "#A50202",
  "&:hover": {
    transition: "all",
  },
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  listStyle: "none",
  display: "flex",
  color: "#1E2928",
  fontFamily: "Inter",
  fontWeight: "bold",
  fontSize: "30px",
  marginTop: "10px",
  padding: "10px 0px",
}));

const StyledContent = styled(Typography)(({ theme }) => ({
  listStyle: "none",
  display: "flex",
  color: "#1E2928",
  fontFamily: "Inter",
  textAlign: "justify",
  fontSize: "15px",
  padding: "10px 0px",
}));

const AboutUsSection = () => {
  return (
    <div className="about-us-grid">
      <div className="about-img-container">
        <img
          src={"Group-11.png"}
          alt="Your description"
          style={{ width: "90%", height: "100%" }}
        />
      </div>
      <div className="about-content-container">
        <StyledLabel>Quienes somos</StyledLabel>
        <StyledTitle>Tu Seguridad, Nuestra Prioridad:</StyledTitle>
        <StyledContent>
          En Cerrajeros 24hs, contamos con un equipo de profesionales altamente
          capacitados y comprometidos con ofrecer soluciones rápidas y
          efectivas. Con más de 20 años de experiencia en el sector, entendemos
          la importancia de brindar seguridad y tranquilidad a nuestros clientes
          en todo momento.
          <br />
          <br />
          Estamos disponibles las 24 horas del día, los 7 días de la semana,
          para resolver cualquier urgencia relacionada con cerraduras y sistemas
          de seguridad. Nos enorgullece brindar un servicio confiable, con el
          respeto y la dedicación que cada cliente merece, logrando siempre los
          mejores resultados.
        </StyledContent>
        {/* <div className="about-us-grid">
          <div className="about-number-grid">
            <StyledTitle
              style={{
                fontSize: 35,
                color: "black",
                padding: "0px",
                marginBottom: "0px",
                fontWeight: "bold",
              }}
            >
              24
            </StyledTitle>
            <StyledContent
              style={{
                fontWeight: "bold",
                padding: "0px",
              }}
            >
              Horas
            </StyledContent>
          </div>
          <div>
            <StyledTitle
              style={{
                fontSize: 35,
                color: "black",
                padding: "0px",
                marginBottom: "0px",
                fontWeight: "bold",
              }}
            >
              365
            </StyledTitle>
            <StyledContent
              style={{
                fontWeight: "bold",
                padding: "0px",
              }}
            >
              Días del año
            </StyledContent>
          </div>
        </div> */}
      </div>
    </div>
  );
};
export default AboutUsSection;

import { styled, Typography } from "@mui/material";
import CallButton from "../../components/Callbutton";
import Navbar from "../../components/Navbar";
import WhatsAppButton from "../../components/WhatsAppButton";

const StyledTitle = styled(Typography)(({ theme }) => ({
  listStyle: "none",
  display: "flex",
  color: "#1E2928",
  fontFamily: "Inter",
  fontSize: "20px",
  padding: "10px 0px",
  textAlign: "start",
  textTransform: "uppercase",
  fontWeight: "bold",
}));
const StyledContent = styled(Typography)(({ theme }) => ({
  listStyle: "none",
  display: "flex",
  color: "#1E2928",
  fontFamily: "Inter",
  textAlign: "justify",
  fontSize: "15px",
  width: "100%",
}));

const Villareal = () => {
  return (
    <>
      <Navbar />
      <WhatsAppButton />
      <CallButton />
      <div className="location-main-container">
        <div className="poblation-banner-villareal">
          <StyledTitle
            sx={{
              fontSize: "35px",
              textAlign: "center",
              fontWeight: "bold",
              color: "white",
              textTransform: "uppercase",
            }}
          >
            Villareal
          </StyledTitle>
        </div>
        <br />
        <StyledTitle>CERRAJERÍA LAS 24 HORAS EN VILLARREAL</StyledTitle>
        <div style={{ borderBottom: "1px solid #fecaca" }}></div>
        <br />
        <StyledContent>
          Puesto que los servicios de cerrajería pueden surgir en los lugares y
          momentos menos esperados, en Aldati Cerrajeros nos hemos expandido
          nuestro radio de acción, desde nuestros inicios en Castellón hasta
          llegar a toda la provincia, y dentro de estas zonas, se encuentra
          especialmente la de nuestros cerrajeros en Villarreal, dado que es una
          de las zonas más importantes de todo Castellón, y puesto que por su
          localización y su población. Villarreal tiende a sufrir más robos en
          viviendas, por lo que necesitamos asegurar más pisos.
        </StyledContent>
        <br />
        <StyledContent>
          Para todo el área de Villarreal contamos con un cerrajero que reside
          en la zona, algo que nos permite desplazarnos a cada trabajo con el
          menor tiempo de espera posible. En esta página pasamos a detallaros
          todos los servicios que realizamos en Villarreal, las 24 horas.
        </StyledContent>
        <br />
        <br />
        <StyledTitle>
          SERVICIOS DE NUESTROS CERRAJEROS DE VILLARREAL
        </StyledTitle>
        <div style={{ borderBottom: "1px solid #fecaca" }}></div>
        <br />
        <StyledContent>
          De igual forma que os explicábamos arriba, Villarreal supone una de
          las zonas más importantes de Castellón, y por ello, se convierte en un
          foco de asaltos a hogares. El principal problema es que los asaltantes
          encuentran modos nuevos de entrar en las viviendas, y es por este
          motivo por el cual en Aldati Cerrajeros queremos estar siempre a la
          vanguardia de la seguridad en cerrajería, ofreciendo siempre los
          mejores trabajos.
        </StyledContent>
        <br />
        <StyledTitle sx={{ fontSize: "15px", fontWeight: "bold" }}>
          Apertura de puertas
        </StyledTitle>
        <br />
        <StyledContent>
          Puede ser por ir con prisa, por diferentes descuidos o debido a un
          robo, pero no disponer de nuestras llaves se convierte en una
          situación muy problemática, puesto que nos obliga a tomar medidas de
          manera rápida y muchas veces sin pensar bien en la solución. Debido a
          esto, encontramos una gran cantidad de cerrajeros que sólo intentan
          aprovecharse de esta situación y subir sus precios en cada servicio
          urgente, lo cual para Aldati Cerrajeros es algo totalmente
          inadmisible.
        </StyledContent>
        <br />
        <StyledContent>
          En este grupo de cerrajeros tenemos unos precios fijos para nuestros
          servicios de apertura de puertas urgentes, por lo que siempre podréis
          conocer el coste de éstas con anterioridad. Otro de los beneficios de
          contar con nosotros es que nuestro cerrajero reside en la propia
          Villarreal, por lo que el tiempo de espera será mínimo, sin tiempos de
          espera para entrar en casa de nuevo.
        </StyledContent>
        <br />
        <StyledContent>
          Nos dedicamos a las aperturas de todo tipo, desde puertas de casa
          comunes, o puertas blindadas de seguridad, puertas y persianas de
          locales o cajas fuertes de cualquier modelo.
        </StyledContent>
        <br />
        <StyledTitle sx={{ fontSize: "15px", fontWeight: "bold" }}>
          Cambio de cerraduras
        </StyledTitle>
        <br />
        <StyledContent>
          Debido al elevado número de robos en hogares que está sufriendo el
          área de Villarreal, la mejor opción que podemos encontrar para
          defendernos es el cambio de cerradura, puesto que con una cerradura de
          seguridad se dificulta tanto el acceso que deja de ser rentable para
          quien quiera vulnerarla.
        </StyledContent>
        <br />
        <StyledContent>
          En Aldati Cerrajeros siempre damos el mismo consejo; nunca trabajar
          con cualquier modelo de cerradura, sobre todo para abaratar costes,
          puesto que la seguridad es uno de los aspectos más importantes que
          hay. Los bombines baratos que podemos encontrar en el mercado no están
          preparados para proteger contra los métodos de asalto punteros, como
          el famoso “bumping”. Dada nuestra experiencia en seguridad,
          recomendamos siempre hacer una buena inversión en un elemento que
          durará décadas y protegerá tanto tu hogar como a tu familia, una
          cerradura que disponga de llaves imposibles de copiar o protección
          contra toda técnica de asalto.
        </StyledContent>
        <br />
        <StyledContent>
          Siempre suministramos las mejores marcas del mercado de las
          cerraduras, tanto nacionales como de importación, las cuales no darán
          problemas y lo mejor, que protegerán tu hogar y tu familia sin
          problemas.
        </StyledContent>
        <br />
        <br />
        <StyledTitle>SOBRE LA CERRAJERÍA EN VILLARREAL</StyledTitle>
        <div style={{ borderBottom: "1px solid #fecaca" }}></div>
        <br />
        <StyledContent>
          Dado que Villarreal conforma una de las poblaciones más importantes de
          Castellón, su elevada población hace que los trabajos de cerrajería
          sean muy comunes. En Aldati Cerrajeros siempre trabajamos para ofrecer
          unos servicios de cerrajería de primer nivel, y sobre todo, con unos
          precios bajos y ajustados a la realidad del sector, de forma que
          volver a entrar en tu casa no suponga un problema más allá de la
          espera hasta que lleguemos.
        </StyledContent>
        <br />
        <br />
        <br />
      </div>
      <div className="galery-footer">
        <Typography
          sx={{
            color: "white",
            fontFamily: "Inter",
            fontWeight: "regular",
            width: "80%",
            textAlign: "center",
          }}
        >
          ©2024 CERRAJERIA 24hs. todos los derechos reservados
        </Typography>
      </div>
    </>
  );
};
export default Villareal;
